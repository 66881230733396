@import "variables";
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../vendor/mea/data-table/styles/data-table";

html, 
body, 
.s-admin {
    height: 100%;
}

.s-admin {
    display: flex;
}

.s-content {
    flex: 1;
    overflow: auto;
}

.s-sidebar {
    background-color: #34495e;
    height: 100%;
    width: 200px;

    &, 
    & a {
        color: white;
    }

    .btn {
        background: transparent;
        border: 0;
        color: white;
        margin: 0;
        display: block;
        padding: 10px 15px;
        text-align: left;

        &:hover {
            text-decoration: underline;
        }
    }

    img {
        max-width: 32px;
        display: inline-block;
        margin-right: 10px;
    }

    ul,
    li {
        padding: 0;
        margin: 0;
        display: block;
    }

    .s-sidebar-header {
        background-color: #2c3e50;

        a {
            display: block;
            color: white;
            font-size: 16px;
            font-weight: normal;
            padding: 10px 15px;
            margin: 0;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
            line-height: 1.1;
        }
    }
}

.s-admin .well {
    background: transparent;
    border: 0;
}

.s-admin h1 {
    font-size: 16px;
    font-weight: normal;
    padding: 10px 15px;
    margin: 0;
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    line-height: 1.1;
}

.s-admin table {
    background-color: white;
}

.s-admin .panel {
    margin-top: 15px;
}

.s-content {
    .alert {
        margin-bottom: 0;
    }
}

.form-group .btn {
    min-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
}

hr {
    border-color: #bdc3c7;
}
