.mea-data-table-wrapper {
    .dataTables_length {
        float: left;
        min-width: 180px;
    }

    .dt-buttons {
        float: left;
    }

    .mea-data-table-searchbar .mea-data-table-col-filter {
        padding: 0;
    }

    .mea-data-table-col-filter .form-control {
        width: 100%;
        border: 0;
        border-radius: 0;
    }

    .mea-data-table-scroll {
        overflow: auto;
        min-height: 400px;
        border-bottom: 1px solid #C8C8C8;
    }
}